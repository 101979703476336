import React from 'react';
import { Helmet } from 'react-helmet';
import { clipTextEllipsis } from '../utils/textEllipsis';
import { useSelector } from 'react-redux';

const CommonHead = () => {
  const config = useSelector(({ config }) => config);

  return (
    <Helmet>
      <title>{clipTextEllipsis(config?.head?.title, 60)}</title>
      <meta name="theme-color" content={config.theme.colors.color_primary} />
      {config.head.meta
        .filter((metaTag) => metaTag.name || metaTag.property)
        .map((metaTag) => (
          <meta key={metaTag.name || metaTag.property} {...metaTag} />
        ))}
      {/* those meta items don't come from config.head.meta, it's fine */}
      <meta property="og:url" content={`${config.baseUrl}/`} />
      <meta name="twitter:site" content={config.social.twitter_account} />
      <meta name="twitter:creator" content={config.social.twitter_account} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:image" content={config.theme.logos.main_logo} />
      <meta name="twitter:title" content={clipTextEllipsis(config.head.title, 70)} />
      <meta property="og:title" content={clipTextEllipsis(config.head.title, 50)} />
      <meta name="twitter:description" content={clipTextEllipsis(config.head.description, 200)} />
      <meta property="og:description" content={clipTextEllipsis(config.head.description, 160)} />
      <link rel="icon" href={config.theme.logos.main_favicon} />
      <link rel="apple-touch-icon" href="/apple-touch-icon.png" sizes="180x180" />
      <link rel="icon" href="/assets/icons/icon.svg" type="image/svg+xml" />
      {/** Google Structured Data for search-enabled websites */}
      <script type="application/ld+json">{`{
          "@context": "https://schema.org",
          "@type": "WebSite",
          "url": "${config.baseUrl}/",
          "potentialAction": [{
            "@type": "SearchAction",
            "target": "${config.baseUrl}/${config.cmsSlugs.search}/{search_term_string}",
            "query-input": "required name=search_term_string"
          }]
        }`}</script>

      {/** Google Structured Data for the organization */}
      <script type="application/ld+json">{`{
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "${config.baseUrl}/",
          "logo": "https://res.cloudinary.com/crop/image/fetch/f_auto,q_70,w_195,c_scale,fl_lossy/${config.theme.logos.schema_logo}"
        }`}</script>
    </Helmet>
  );
};

export default CommonHead;
