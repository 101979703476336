//ARD prefix stands for App Routes Definitions
export const ARD_HOME = 'ARD_HOME';
export const ARD_ALL_CATEGORIES = 'ARD_ALL_CATEGORIES';
export const ARD_ALL_SHOPS = 'ARD_ALL_SHOPS';
export const ARD_BLOG = 'ARD_BLOG';
export const ARD_BLOGS = 'ARD_BLOGS';
export const ARD_BUSINESS = 'ARD_BUSINESS';
export const ARD_COOKIES = 'ARD_COOKIES';
export const ARD_CART_OVERVIEW = 'ARD_CART_OVERVIEW';
export const ARD_CHECKOUT = 'ARD_CHECKOUT';
export const ARD_LOGOUT = 'ARD_LOGOUT';
export const ARD_OFFLINE = 'ARD_OFFLINE';
export const ARD_PRIVACY = 'ARD_PRIVACY';
export const ARD_PRODUCT = 'ARD_PRODUCT';
export const ARD_PROFILE = 'ARD_PROFILE';
export const ARD_PROFILE_ORDERS_N_RETURNS = 'ARD_PROFILE_ORDERS_N_RETURNS';
export const ARD_PROFILE_ADDRESSES = 'ARD_PROFILE_ADDRESSES';
export const ARD_PROFILE_WISHLIST = 'ARD_PROFILE_WISHLIST';
export const ARD_PROFILE_PERSONAL_INFO = 'ARD_PROFILE_PERSONAL_INFO';
export const ARD_PROFILE_PERSONALIZATION = 'ARD_PROFILE_PERSONALIZATION';
export const ARD_PROFILE_NEWSLETTER = 'ARD_PROFILE_NEWSLETTER';
export const ARD_PROFILE_BOOKMARKS = 'ARD_PROFILE_BOOKMARKS';
export const ARD_PROFILE_RECENT_PRODUCTS = 'ARD_PROFILE_RECENT_PRODUCTS';
export const ARD_PROFILE_NOTIFICATION_CENTER = 'ARD_PROFILE_NOTIFICATION_CENTER';
export const ARD_SEARCH = 'ARD_SEARCH';
export const ARD_PERMA_SEARCH = 'ARD_PERMA_SEARCH';
export const ARD_CATEGORY_CONTENT = 'ARD_CATEGORY_CONTENT';
export const ARD_SUB_CATEGORY_CONTENT = 'ARD_SUB_CATEGORY_CONTENT';
export const ARD_SUB_SUB_CATEGORY_CONTENT = 'ARD_SUB_SUB_CATEGORY_CONTENT';
export const ARD_CATEGORY_SEARCH = 'ARD_CATEGORY_SEARCH';
export const ARD_SUB_CATEGORY_SEARCH = 'ARD_SUB_CATEGORY_SEARCH';
export const ARD_SUB_SUB_CATEGORY_SEARCH = 'ARD_SUB_SUB_CATEGORY_SEARCH';
export const ARD_SIGNUP = 'ARD_SIGNUP';
export const ARD_SHOP = 'ARD_SHOP';
export const ARD_TERMS_AND_CONDITIONS = 'ARD_TERMS_AND_CONDITIONS';
export const ARD_NOT_FOUND = 'ARD_NOT_FOUND';
export const ARD_ERROR = 'ARD_ERROR';
export const ARD_CHAT = 'ARD_CHAT';
export const ARD_CHAT_INIT = 'ARD_CHAT_INIT';

export const ARD_POPUP_LOGIN = 'ARD_POPUP_LOGIN';
export const ARD_POPUP_PROFILE = 'ARD_POPUP_PROFILE';
export const ARD_POPUP_PROFILE_PERSONAL_INFO = 'ARD_POPUP_PROFILE_PERSONAL_INFO';
export const ARD_POPUP_PROFILE_ORDERS = 'ARD_POPUP_PROFILE_ORDERS';
export const ARD_POPUP_PROFILE_BOOKMARKS = 'ARD_POPUP_PROFILE_BOOKMARKS';
export const ARD_POPUP_PROFILE_RECENT_PRODUCTS = 'ARD_POPUP_PROFILE_RECENT_PRODUCTS';
export const ARD_POPUP_PROFILE_WISHLIST = 'ARD_POPUP_PROFILE_WISHLIST';
export const ARD_POPUP_CART = 'ARD_POPUP_CART';
export const ARD_POPUP_PROFILE_NEWSLETTER = 'ARD_POPUP_PROFILE_NEWSLETTER';
export const ARD_POPUP_MENU = 'ARD_POPUP_MENU';
export const ARD_POPUP_DAILIES = 'ARD_POPUP_DAILIES';
export const ARD_COMING_SOON = 'ARD_COMING_SOON';

export const ARD_CUSTOMER_SERVICE = 'ARD_CUSTOMER_SERVICE';
export const ARD_CUSTOMER_SERVICE_ORDER_AND_PAY = 'ARD_CUSTOMER_SERVICE_ORDER_AND_PAY';
export const ARD_CUSTOMER_SERVICE_RETURN_AND_CANCEL = 'ARD_CUSTOMER_SERVICE_RETURN_AND_CANCEL';
export const ARD_CUSTOMER_SERVICE_WARRANTY_AND_REPAIR = 'ARD_CUSTOMER_SERVICE_WARRANTY_AND_REPAIR';
export const ARD_CUSTOMER_SERVICE_DELIVERY = 'ARD_CUSTOMER_SERVICE_DELIVERY';
export const ARD_CUSTOMER_SERVICE_USER_ACCOUNT_AND_TERM = 'ARD_CUSTOMER_SERVICE_USER_ACCOUNT_AND_TERM';
export const ARD_CUSTOMER_SERVICE_SHOP_SELLER = 'ARD_CUSTOMER_SERVICE_SHOP_SELLER';
export const ARD_CUSTOMER_SERVICE_COMPLAIN = 'ARD_CUSTOMER_SERVICE_COMPLAIN';

export const ARD_ORDER_DETAILS = 'ARD_ORDER_DETAILS';
