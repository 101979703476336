import { getValidResponseBody, staticApi } from '../../api';

export const AllShopsPageDataLoader = async () => {
  try {
    const allActiveShopsResp = await staticApi.getAllShops();
    const allActiveShops = getValidResponseBody(allActiveShopsResp);

    if (!allActiveShopsResp?.ok) throw new Response('Not Found', { status: 404 });

    return { allActiveShops };
  } catch (error) {
    throw new Response(error.message, { status: 404 });
  }
};
