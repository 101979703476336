import { redirect } from 'react-router-dom';
import { getValidResponseBody, productApi, searchApi } from '../../api';

export const PDPDataLoader = async ({ params }) => {
  const { productId } = params;
  try {
    const productEndpointGetParams = '?withExpired=1&withRedirects=1';
    const productItemOption = { withCredentials: false };

    const [productItem, similarItems] = await Promise.all([
      searchApi.getProductItem(productId, productEndpointGetParams, productItemOption),
      productApi.getMoreLikeThis([productId]),
    ]);

    const productInfo = getValidResponseBody(productItem);

    //@todo: rename vars. This should be shouldShowOffersBlock
    const hasPriceChecker =
      !!productInfo &&
      !!(
        productInfo?.offers?.[0]?.EAN ||
        productInfo.GTIN ||
        productInfo.ISBN ||
        (productInfo.brand && productInfo?.offers?.[0]?.SKU) ||
        (productInfo.campaignID && productInfo.family_code)
      );

    const bodyOption = {
      id: productInfo?.id ? productInfo.id : '',
      brand: productInfo?.brand ? productInfo.brand : undefined,
      campaignID: productInfo?.campaignID ? productInfo.campaignID : '',
      EAN: productInfo?.offers?.[0]?.EAN ? productInfo.offers?.[0].EAN : '',
      family_code: productInfo?.family_code,
      SKU: productInfo?.offers?.[0]?.SKU ? productInfo.offers?.[0].SKU : undefined,
    };
    const productShopsOption = {
      method: 'POST',
      body: JSON.stringify(bodyOption),
    };
    const productShops = hasPriceChecker ? await searchApi.getProductOffers(productShopsOption) : null;
    const shopOffersBody = getValidResponseBody(productShops);

    const isRedirectRequested = !!productItem?.body?.location;
    if (isRedirectRequested) return redirect(productItem?.body?.location);

    const isProductError = !productItem.ok;
    const isProduct404 = productInfo === null || productItem?.status === 404;
    //todo: implement separate Server/Application Error page for api errors
    //but keep in mind product api returns 400 for not found products too. So status codes are to be verified
    if (isProduct404 || isProductError) throw new Response('Not Found', { status: 404 });

    //todo: map is different for MP version. for some reason it omits id
    const similarProducts = getValidResponseBody(similarItems)?.hits?.hits.map(({ _source, _id }) => ({
      ..._source,
      id: _id,
    }));
    return { productInfo, similarProducts, shopOffersBody, hasPriceChecker };
  } catch (error) {
    throw new Response(error.message, { status: 404 });
  }
};
