import getSearchPayload from '../../utils/getSearchPayload';

export const getSearchApiOptions = (
  filters,
  page,
  priceFrom,
  priceTo,
  searchTerm,
  categoriesParamArray,
  maxProductsPerPage,
  urlSortingOption,
  featuresFilter,
  uberfilter,
  authHeader,
  isDesktop
) => {
  const searchParams = {
    brandsFilter: filters.brand,
    colorsFilter: filters.colors,
    gendersFilter: filters.genders,
    modelsFilter: filters.models,
    deliveryFilter: filters.delivery,
    shopsFilter: filters.shops,
    sizesFilter: filters.sizes,
    currentPage: page,
    categoryFilter: categoriesParamArray,
    featuresFilter,
    priceFrom,
    priceTo,
    searchTerm,
    size: maxProductsPerPage,
    sort: urlSortingOption,
    uberfilter,
    wpCategory: categoriesParamArray?.[categoriesParamArray.length - 1] || undefined,
  };

  const searchPayload = getSearchPayload(searchParams);
  const options = {
    headers: { ...authHeader },
    method: 'POST',
    body: JSON.stringify(searchPayload),
    mobile: !isDesktop,
  };

  return { options, searchPayload };
};
