import { contentApi, getValidResponseBody } from '../../api';

export const BlogGridDataLoader = async () => {
  try {
    const blogDataResp = await contentApi.getBlogData();

    if (!blogDataResp?.ok) throw new Response('Not Found', { status: 404 });

    const blogData = getValidResponseBody(blogDataResp);

    return { blogData };
  } catch (error) {
    throw new Response(error.message, { status: 404 });
  }
};
