/**
 * composeDataLoaders - Compose multiple data loaders into a single one. Allows running data loaders from deeply nested components.
 * @param {Object} dataLoadersObject - Structure: { loaderDataKey1: dataLoaderFn1, loaderDataKeyN: dataLoaderFnN }
 * @returns {Function} - React Router data loader
 */
export const composeDataLoaders = (dataLoadersObject) => async (...dataLoaderArgs) => {
  const entries = Object.entries(dataLoadersObject);

  // Execute all loaders concurrently with error handling
  const responses = await Promise.allSettled(
    entries.map(([, loader]) => loader(...dataLoaderArgs).catch((err) => ({ error: err.message || err.toString() })))
  );

  // Reduce responses to a structured object
  return responses.reduce((acc, result, index) => {
    const key = entries[index][0];
    acc[key] = result.status === 'fulfilled' ? result.value : { error: result.reason };
    return acc;
  }, {});
};
