import React from 'react';
import { SwitchableFeature } from '../features/SwitchableFeature/SwitchableFeature';
import loadVisibleComponent from '../utils/loadVisibleComponent';
import { DAILY_BAR } from 'Features/SwitchableFeature/SwitchableFeature.definitions';
import { CART } from '../features/SwitchableFeature/SwitchableFeature.definitions';
import usePaymentModalHandler from './usePaymentModalHandler';

//Load following components on client side. This excludes them from SSR generated chunks
const ExpiredSessionModal = loadVisibleComponent(() => import('../components/ExpiredSessionModal'));
const PaymentStatusPopup = loadVisibleComponent(() => import('../components/PaymentStatusPopup/PaymentStatusPopup'));
const NotificationPopup = loadVisibleComponent(() => import('../components/NotificationPopup'));
const DailyBar = loadVisibleComponent(() => import('../components/DailyBar/DailyBar'));
const LogoutModal = loadVisibleComponent(() => import('../components/LogoutModal'));
const CartBootstrap = loadVisibleComponent(() => import('../components/CartBootstrap/CartBootstrap'));

const OffScreen = () => {
  const {
    paymentStatusPopupShown,
    closePaymentStatusPopup,
    processedOrderId,
    paymentSuccess,
  } = usePaymentModalHandler();

  return (
    <>
      {/* todo: drops a couple of points down */}
      {/* todo: request those components on demand, exclude from bandle when feature is disabled */}
      <SwitchableFeature
        dependencies={[
          [DAILY_BAR, true],
          [CART, false],
        ]}
      >
        <DailyBar />
      </SwitchableFeature>
      <NotificationPopup />
      <ExpiredSessionModal isOpen={false} />
      <SwitchableFeature feature={CART}>
        <CartBootstrap />
      </SwitchableFeature>
      {paymentStatusPopupShown && (
        <PaymentStatusPopup onClose={closePaymentStatusPopup} success={paymentSuccess} orderId={processedOrderId} />
      )}
      <LogoutModal />
    </>
  );
};

export default OffScreen;
