import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setClk } from '../state/clickout/clickout';

const useClickoutData = () => {
  const dispatch = useDispatch();
  const setClickoutData = useMemo(
    (clk) => {
      dispatch(setClk(clk));
    },
    [dispatch]
  );

  useEffect(() => {
    window.addEventListener('load', () => {
      const url = new URLSearchParams(window.location.search);
      const urlClk = url.get('clk');
      const sessionClk = sessionStorage?.getItem('clk');
      const ck = urlClk || sessionClk;

      if (urlClk) sessionStorage.setItem('clk', urlClk);

      if (ck) {
        setClickoutData(ck);
      }
    });
  }, [setClickoutData]);
};

export default useClickoutData;
