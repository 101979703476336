import { contentApi, getValidResponseBody } from '../../api';
import { isServer } from '../../utils/isServer';

export const BlogPageDataLoader = async ({ params, request }) => {
  try {
    const { slug } = params;
    const postId = slug?.match(/-(\d+)/)?.[1];
    //todo: create a ticket for the following issue with API. When fixes, remove this partial workaround
    //it still doesn't work in case with: https://shops.ae/blog/international-mothers-day-5345435435435/
    //Missing post id in url should lead to 404. Api responds with 200 for some reason
    if (postId === undefined || postId === null) throw new Response('Not Found', { status: 404 });

    const reqHeaders = request?.headers;
    //headers to be added from express req to blog related api request body on SSR only
    const blogRequestHeaderKeys = ['cookie', 'user-agent', 'x-real-ip', 'x-forwarded-for', 'x-request-id'];
    //x- headers are not available on localhost by default
    //pass only existing headers
    const blogsRequestHeadersParam = isServer
      ? blogRequestHeaderKeys.reduce(
          (prev, key) => (reqHeaders.get(key) ? { ...prev, [key]: reqHeaders.get(key) } : prev),
          {}
        )
      : null;

    const [blogPageData, blogData] = await Promise.all([
      contentApi.getBlogPostData(postId, blogsRequestHeadersParam),
      contentApi.getBlogData(blogsRequestHeadersParam),
    ]);

    if (!blogPageData?.ok) throw new Response('Not Found', { status: 404 });

    const data = getValidResponseBody(blogPageData);
    const allPostsData = getValidResponseBody(blogData);

    return { data, allPostsData };
  } catch (error) {
    throw new Response(error.message, { status: 404 });
  }
};
