import { isServer } from './isServer';
import { parseValidJSON } from './parseValidJSON';

export const getAuthHeader = () => {
  const headers = {};
  if (isServer || !localStorage) return headers;
  const lsTokenClaims = parseValidJSON(localStorage.getItem('tokenClaims'));
  const authToken = lsTokenClaims?.__raw;

  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  return headers;
};
