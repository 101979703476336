/* eslint-disable react/prop-types */
import React from 'react';
// eslint-disable-next-line react/no-deprecated
import { hydrate } from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { loadableReady } from '@loadable/component';
import { createFrontloadState } from 'react-frontload';
import * as serviceWorker from '../serviceWorkerRegistration';

import createStore from '../state';
import { setConfig } from '../state/config/config';
import { contentApi, searchApi, userApi, taxiApi, reviewsApi, staticApi, discountApi, productApi } from '../api';
import { setSSRContextValues } from '../utils/SSRContext';
import { initDeferredHydration } from './deferredHydration';
import { updateAppRoutes } from '../utils/appRoutes';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import getRoutes from '../app/routes';
import CrossEnvProviders from '../app/CrossEnvProviders';
import Cookies from 'universal-cookie';

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_NAME === 'webshop-nl') {
  Sentry.init({
    dsn: 'https://fa76a985f7b747ccb0c1682bffd33e88@o596641.ingest.sentry.io/5798844',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.8,
  });
}

const { store } = createStore();
const state = store.getState();
const cmsSlugs = state.config?.cmsSlugs;

if (cmsSlugs) updateAppRoutes(cmsSlugs);

const frontloadState = createFrontloadState.client({
  // add api to context here, so that it is available for frontloads
  context: { contentApi, searchApi, userApi, taxiApi, reviewsApi, staticApi, discountApi, productApi },
  //FIXME: why do we have window.window here?
  serverRenderedData: window.window.__FRONTLOAD_DATA__,
  logging: true,
});
//todo: can this getter be expensive?
store.dispatch(
  setConfig({
    browserInfo: { viewportHeight: window.innerHeight, viewportWidth: window.innerWidth },
  })
);

const ssrContextObject = setSSRContextValues({
  isValidCategorySSR: window.isValidCategorySSR,
  isDesktopSSR: window.isDesktopSSR,
  SSRPath: window.SSRPath,
});

const invokeHydration = () => {
  const config = state.config;
  const cookies = new Cookies(document.cookie, { path: '/' });
  let router = createBrowserRouter(getRoutes(cookies, config));

  hydrate(
    <CrossEnvProviders store={store} frontloadState={frontloadState} ssrContextObject={ssrContextObject}>
      <RouterProvider router={router} />
    </CrossEnvProviders>,
    document.getElementById('root')
  );
};

const deferredHydrationDisabled = localStorage?.getItem('deferredHydrationDisabled');
const immediateHydration = deferredHydrationDisabled && JSON.parse(deferredHydrationDisabled);

if (immediateHydration) {
  loadableReady(invokeHydration);
} else {
  loadableReady(() => {
    initDeferredHydration(invokeHydration);
  });
}

if (module.hot) {
  module.hot.accept();
}

serviceWorker.register();
