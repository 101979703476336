import { useLocation, useNavigate } from 'react-router-dom';
import dataLayerPush from '../utils/dataLayerPush';
import { isMPGTMEnabled } from '../utils/isGTMEnabled';

const usePaymentModalHandler = () => {
  const { hash: locationHash } = useLocation();
  const navigate = useNavigate();

  const paymentIncomplete = locationHash.includes('payment_cancel');
  const paymentSuccess = locationHash.includes('payment_success');
  const processedOrderId = locationHash?.split('+')?.[1];

  if (paymentSuccess && isMPGTMEnabled) {
    const purchaseAttemptProductIds = localStorage.getItem('purchase_attempt_pids');
    const productIds = purchaseAttemptProductIds && JSON.parse(purchaseAttemptProductIds);
    if (productIds?.length) {
      dataLayerPush({ event: 'purchase_success', product_ids: productIds });
      localStorage.removeItem('purchase_attempt_pids');
    }
  }

  const paymentStatusPopupShown = paymentIncomplete || paymentSuccess;

  const closePaymentStatusPopup = () => {
    navigate('#');
  };

  return { paymentStatusPopupShown, closePaymentStatusPopup, processedOrderId, paymentSuccess };
};

export default usePaymentModalHandler;
