import { combineReducers } from 'redux';

import { reducer as config } from '../config/config';
import { reducer as intl } from '../intl/intl';
import { reducer as dailies } from '../dailies/dailies';
import { reducer as categories } from '../categories/categories';
import { reducer as searchHistory } from '../searchHistory/searchHistory';
import { reducer as profile } from '../profile/profile';
import { reducer as anonymousLikedProducts } from '../anonymousLikedProducts/anonymousLikedProducts';
import { reducer as anonymousRecentlyViewedProducts } from '../anonymousRecentlyViewedProducts/anonymousRecentlyViewedProducts';
import { reducer as clickout } from '../clickout/clickout';
import { reducer as cart } from '../cart/cart';
import { reducer as notifications } from '../notifications/notifications';
import { reducer as recommendation } from '../recommendation/recommendation';
import { reducer as productSearch } from '../productSearch/productSearch';
import { reducer as wishlist } from '../wishlist/wishlist';
import { reducer as stockSubscription } from '../stockSubscriptions/stockSubscriptions';
import { reducer as ui } from '../ui/ui';

export default () => {
  const reducers = {
    categories,
    config,
    dailies,
    intl,
    searchHistory,
    profile,
    anonymousLikedProducts,
    anonymousRecentlyViewedProducts,
    clickout,
    cart, //todo: apply and load reducer as a feature
    notifications,
    recommendation,
    productSearch,
    wishlist,
    stockSubscription,
    ui,
  };

  const combinedReducers = combineReducers(reducers);

  return combinedReducers;
};
