import { contentApi, getValidResponseBody } from '../../api';

export const BusinessPageDataLoader = async () => {
  try {
    const latestHomeData = await contentApi.getLatestHomeData();

    const dataBusinessPage = getValidResponseBody(latestHomeData);

    if (!latestHomeData?.ok) throw new Response('Not Found', { status: 404 });

    return { dataBusinessPage };
  } catch (error) {
    throw new Response(error.message, { status: 404 });
  }
};
