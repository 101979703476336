import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setDeferredPWAInstallPrompt } from '../state/ui/ui';

export const usePWAInstallPromptInit = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevents the default mini-infobar or install dialog from appearing on mobile
      e.preventDefault();
      // Save the event because you'll need to trigger it later.
      window.deferredPWAInstallPrompt = e;
      // Show your customized install prompt for your PWA
      // Your own UI doesn't have to be a single element, you
      // can have buttons in different locations, or wait to prompt
      // as part of a critical journey.
      //showInAppInstallPromotion();
      dispatch(setDeferredPWAInstallPrompt(e));
    });
  }, [dispatch]);
};
