import { getValidResponseBody, staticApi } from '../../api';

export const CategoryPageDataLoader = async ({ params }) => {
  try {
    const { category, subCategory, subSubCategory } = params;
    const joinedCategories = [category, subCategory, subSubCategory].filter(Boolean).join('.');
    const pageData = await staticApi.getCategories2Data(joinedCategories);

    if (!pageData?.ok) throw new Response('Not Found', { status: 404 });

    const categoryData = getValidResponseBody(pageData);

    return { categoryData };
  } catch (error) {
    throw new Response(error.message, { status: 404 });
  }
};
