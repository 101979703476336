import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { parseFeatureFlags } from '../utils/parseFeatureFlags';
const useSwitchableFeatureBootstrap = (search) => {
  const [cookies, setCookie] = useCookies(['enabledFeatureFlags']);

  useEffect(() => {
    //cookie should be set on server side
    if (search) parseFeatureFlags(search, setCookie);
  }, [search, setCookie]);
};

export default useSwitchableFeatureBootstrap;
