import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { isFeatureEnabled } from '../features/SwitchableFeature/SwitchableFeature';
import { useLayoutEffect } from 'react';
import { CART } from '../features/SwitchableFeature/SwitchableFeature.definitions';
import { isGTMEnabled, isMPGTMEnabled } from '../utils/isGTMEnabled';
import { parseValidJSON } from '../utils/parseValidJSON';
import { isProduction } from '../utils/isProduction';
import { replayEvents } from '../client/eventCollector';
import { cleanHydrationEventListeners } from '../client/deferredHydration';

/**
 * Inject third party scripts like GTM, analytics on client side
 */
const useClientScriptInjector = () => {
  const [cookies] = useCookies(['enabledFeatureFlags']);
  const config = useSelector(({ config }) => config);

  const marketplaceVersion = isFeatureEnabled(cookies, config, CART);
  useLayoutEffect(() => {
    setTimeout(() => {
      //todo: If replayEvents launched without setTimeout, it is called recursively
      cleanHydrationEventListeners();
      replayEvents();

      const mapScript = document.createElement('script');
      mapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places,geometry`;
      mapScript.async = true;
      mapScript.defer = true;
      document.querySelector('body').appendChild(mapScript);

      //add GTM to the app
      //REACT_APP_GTM_ID is added as a new tag for MP webshop
      if (isMPGTMEnabled) {
        const lsTokenClaims = parseValidJSON(localStorage.getItem('tokenClaims'));
        const userId = lsTokenClaims?.sub;
        const userIdLiteral = userId ? `"${userId}"` : 'undefined';

        const GTM_Script = document.createElement('script');
        const noscript = document.createElement('noscript');
        //The order of the code here is vital. If your consent code is called out of order, consent defaults will not work
        //https://developers.google.com/tag-platform/security/guides/consent?consentmode=advanced#gtag.js_3
        GTM_Script.innerHTML = `
          ;window.dataLayer = window.dataLayer || [];

          (function() {            
            const userId = ${userIdLiteral};
            if (userId) {
              window.dataLayer.push({
                user_id: userId,
              });
            }
          })();
          
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.REACT_APP_GTM_ID}');`;

        noscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.REACT_APP_GTM_ID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
        document.head.insertBefore(GTM_Script, document.head.childNodes[0]);
        document.body.insertBefore(noscript, document.body.childNodes[0]);

        const clarityScript = document.createElement('script');
        clarityScript.innerHTML = `
        (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "oiyy8jiklo");
        `;
        document.querySelector('head').appendChild(clarityScript);
      } else if (isGTMEnabled) {
        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA4_ID}`;
        script.async = true;
        document.querySelector('body').appendChild(script);
        //todo: identify on which environment this code (from data layer init to gtag config) supposed to work
        //todo: make sure this code is working as indended
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }

        gtag('js', new Date());
        gtag('config', process.env.REACT_APP_GA4_ID);

        const clarityScript = document.createElement('script');
        clarityScript.innerHTML = `
        (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "oiyy8jiklo");
        `;
        document.querySelector('head').appendChild(clarityScript);
      }

      if (isProduction) {
        //script apm rum on localhost, but trigget on all (dev/stage/prod) domains
        const APMRUMScript = document.createElement('script');
        const versionPrefix = marketplaceVersion ? 'mp-' : '';
        const miabClientServiceName = `miab-client-${versionPrefix}${process.env.REACT_APP_WEBSITE_DOMAIN}`.replace(
          /[:\.]/g,
          '-'
        );
        APMRUMScript.innerHTML = `
        ;(function(d, s, c) {
            var j = d.createElement(s),
              t = d.getElementsByTagName(s)[0]

            j.src = '${process.env.REACT_APP_BASE_URL}/elastic-apm-rum.umd.min-5.16.1.js'
            j.onload = function() {elasticApm.init(c)}
            t.parentNode.insertBefore(j, t)
          })(document, 'script', {environment: 'production', serviceName: '${miabClientServiceName}', serverUrl: 'https://miab-monitoring.apm.eu-west-1.aws.found.io'})
        `;

        document.querySelector('head').appendChild(APMRUMScript);
      }
    }, 0);
  }, []);
};

export default useClientScriptInjector;
