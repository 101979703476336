import { getValidResponseBody, staticApi } from '../../api';

export const PopularSearchTermsDataLoader = async () => {
  try {
    const popularTerms = await staticApi.getPopularTerms();
    const data = getValidResponseBody(popularTerms || []);
    return data;
  } catch (error) {
    throw new Response(error.message, { status: 404 });
  }
};
