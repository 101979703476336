import React from 'react';
import loadable from '@loadable/component';

import { BLOGS_LIST_PAGE, CART } from 'Features/SwitchableFeature/SwitchableFeature.definitions';
import { getPageRoute } from '../utils/appRoutes';
import { switchableRoute } from 'Features/SwitchableFeature/SwitchableFeature';
import {
  ARD_ALL_CATEGORIES,
  ARD_ALL_SHOPS,
  ARD_BLOGS,
  ARD_BUSINESS,
  ARD_CART_OVERVIEW,
  ARD_CHECKOUT,
  ARD_COOKIES,
  ARD_HOME,
  ARD_OFFLINE,
  ARD_PRIVACY,
  ARD_PRODUCT,
  ARD_PROFILE,
  ARD_SEARCH,
  ARD_PERMA_SEARCH,
  ARD_SHOP,
  ARD_TERMS_AND_CONDITIONS,
  ARD_CATEGORY_SEARCH,
  ARD_BLOG,
  ARD_CATEGORY_CONTENT,
  ARD_CUSTOMER_SERVICE,
  ARD_ORDER_DETAILS,
  ARD_CHAT,
  ARD_CHAT_INIT,
  ARD_SUB_CATEGORY_SEARCH,
  ARD_SUB_SUB_CATEGORY_SEARCH,
  ARD_SUB_CATEGORY_CONTENT,
  ARD_SUB_SUB_CATEGORY_CONTENT,
} from '../utils/appRoutes.definitions';
import { BLOG_GRID, BLOG_PAGE } from '../features/SwitchableFeature/SwitchableFeature.definitions';
import { PDPMPDataLoader } from '../components/ProductDetailMarketplacePage/PDPMPDataLoader';
import AppLayout from './AppLayout';
import { PDPDataLoader } from '../components/ProductDetailPage/PDPDataLoader';
import { HomePageMPDataLoader } from '../pages/HomePageMarketplace/HomePageMPDataLoader';
import { CategoryPageDataLoader } from '../components/CategoryPage/CategoryPageDataLoader';
import { AllShopsPageDataLoader } from '../components/AllShopsPage/AllShopsPageDataLoader';
import { SDPDataLoader } from '../pages/ShopDetailsPage/SDPDataLoader';
import { InfoPageDataLoader } from '../components/InfoPage/InfoPageDataLoader';
import { BusinessPageDataLoader } from '../components/BusinessPage/BusinessPageDataLoader';
import { BlogPageDataLoader } from '../components/BlogPage/BlogPageDataLoader';
import { BlogListPageDataLoader } from '../components/BlogListPage/BlogListPageDataLoader';
import { SRPDataLoader } from '../components/SearchResultsPage/SRPDataLoader';
import { isFeatureEnabled } from '../features/SwitchableFeature/SwitchableFeature';
import { BlogGridDataLoader } from '../components/BlogGrid/BlogGridDataLoader';
import { composeDataLoaders } from '../utils/composeDataLoaders';
import { PopularSearchTermsDataLoader } from '../components/PopularSearchTerms/PopularSearchTermsDataLoader';
import { NotFoundPageDataLoader } from '../components/NotFoundPage/NotFoundPageDataLoader';

const HomePage = loadable(() => import('../pages/HomePage'));
const HomePageMarketplace = loadable(() => import('../pages/HomePageMarketplace'));
const AuthIndicator = loadable(() => import('../components/AuthIndicator'));
const BlogListPage = loadable(() => import('../components/BlogListPage/BlogListPage'));
const BlogPage = loadable(() => import('../components/BlogPage/BlogPage'));
const BusinessPage = loadable(() => import('../components/BusinessPage/BusinessPage'));
const InfoPage = loadable(() => import('../components/InfoPage/InfoPage'));
const ShopDetailsPage = loadable(() => import('../pages/ShopDetailsPage/ShopDetailsPage'));
const SearchResultsPage = loadable(() => import('../components/SearchResultsPage/SearchResultsPage'));
const CategoryPage = loadable(() => import('../components/CategoryPage/CategoryPage'));
const ProductDetailPage = loadable(() => import('../components/ProductDetailPage'));
const ProductDetailMarketplacePage = loadable(() => import('../components/ProductDetailMarketplacePage'));
const NotFoundPage = loadable(() => import('../components/NotFoundPage/NotFoundPage'));
const ProfilePage = loadable(() => import('../components/ProfilePage'));
const AllShopsPage = loadable(() => import('../components/AllShopsPage/AllShopsPage'));
const AllCategoriesPage = loadable(() => import('../components/AllCategoriesPage/AllCategoriesPage'));
const OfflinePage = loadable(() => import('../components/OfflinePage/OfflinePage'));
const CartOverviewPage = loadable(() => import('../components/CartOverviewPage/CartOverviewPage'));
const CheckoutPage = loadable(() => import('../components/CheckoutPage/CheckoutPage'));
const CustomerServicePage = loadable(() => import('../components/CustomerServicePage'));
const OrderDetailsPage = loadable(() => import('../components/OrderDetailsPage'));
const POC = loadable(() => import('../components/POC'));

const getRoutes = (cookies, config) => {
  const loaderObject = isFeatureEnabled(cookies, config, BLOG_GRID) ? { loader: BlogGridDataLoader } : {};

  return [
    {
      path: getPageRoute(ARD_HOME),
      element: <AppLayout />,
      errorElement: <NotFoundPage />,
      loader: PopularSearchTermsDataLoader,
      children: [
        switchableRoute(cookies, config, CART)
          ? {
              path: '',
              element: <HomePageMarketplace />,
              loader: composeDataLoaders({
                home: HomePageMPDataLoader,
                popularSearchTerms: PopularSearchTermsDataLoader,
              }),
            }
          : {
              path: '',
              element: <HomePage />,
              ...loaderObject,
            },
        switchableRoute(cookies, config, CART) && {
          path: getPageRoute(ARD_CHAT_INIT),
          element: <POC />,
          loader: PopularSearchTermsDataLoader,
        },
        switchableRoute(cookies, config, CART) && {
          path: getPageRoute(ARD_CHAT),
          element: <POC />,
          loader: PopularSearchTermsDataLoader,
        },
        switchableRoute(cookies, config, BLOG_PAGE) && {
          path: getPageRoute(ARD_BLOG),
          element: <BlogPage />,
          loader: BlogPageDataLoader,
        },
        switchableRoute(cookies, config, BLOGS_LIST_PAGE) && {
          path: getPageRoute(ARD_BLOGS),
          element: <BlogListPage />,
          loader: BlogListPageDataLoader,
        },
        // { path: getPageRoute(ARD_LOGOUT), element: <Navigate to="/" /> },
        switchableRoute(cookies, config, CART) && {
          path: getPageRoute(ARD_CART_OVERVIEW),
          element: <CartOverviewPage />,
          loader: PopularSearchTermsDataLoader,
        },
        switchableRoute(cookies, config, CART) && {
          path: getPageRoute(ARD_CHECKOUT),
          element: <CheckoutPage />,
          loader: PopularSearchTermsDataLoader,
        },
        switchableRoute(cookies, config, CART) && {
          path: getPageRoute(ARD_ORDER_DETAILS),
          element: <OrderDetailsPage />,
          loader: PopularSearchTermsDataLoader,
        },
        switchableRoute(cookies, config, CART) && {
          path: getPageRoute(ARD_CUSTOMER_SERVICE),
          element: <CustomerServicePage />,
          loader: PopularSearchTermsDataLoader,
        },
        { path: '/auth-redirect', element: <AuthIndicator /> },
        {
          path: getPageRoute(ARD_PROFILE) + '*',
          element: <ProfilePage featureFlagsCookie={cookies} />,
          loader: PopularSearchTermsDataLoader,
        },
        { path: getPageRoute(ARD_OFFLINE), element: <OfflinePage /> },
        { path: getPageRoute(ARD_ALL_SHOPS), element: <AllShopsPage />, loader: AllShopsPageDataLoader },
        {
          path: getPageRoute(ARD_ALL_CATEGORIES),
          element: <AllCategoriesPage />,
          loader: PopularSearchTermsDataLoader,
        },
        { path: getPageRoute(ARD_SHOP), element: <ShopDetailsPage />, loader: SDPDataLoader },
        { path: getPageRoute(ARD_COOKIES), element: <InfoPage />, loader: InfoPageDataLoader },
        { path: getPageRoute(ARD_PRIVACY), element: <InfoPage />, loader: InfoPageDataLoader },
        { path: getPageRoute(ARD_BUSINESS), element: <BusinessPage />, loader: BusinessPageDataLoader },
        { path: getPageRoute(ARD_TERMS_AND_CONDITIONS), element: <InfoPage />, loader: InfoPageDataLoader },
        switchableRoute(cookies, config, CART)
          ? {
              path: getPageRoute(ARD_PRODUCT),
              element: <ProductDetailMarketplacePage />,
              loader: PDPMPDataLoader,
            }
          : {
              path: getPageRoute(ARD_PRODUCT),
              element: <ProductDetailPage />,
              loader: PDPDataLoader,
            },
        { path: getPageRoute(ARD_CATEGORY_CONTENT), element: <CategoryPage />, loader: CategoryPageDataLoader },
        { path: getPageRoute(ARD_SUB_CATEGORY_CONTENT), element: <CategoryPage />, loader: CategoryPageDataLoader },
        { path: getPageRoute(ARD_SUB_SUB_CATEGORY_CONTENT), element: <CategoryPage />, loader: CategoryPageDataLoader },
        { path: getPageRoute(ARD_PERMA_SEARCH), element: <SearchResultsPage />, loader: SRPDataLoader },
        { path: getPageRoute(ARD_SEARCH), element: <SearchResultsPage />, loader: SRPDataLoader },
        { path: getPageRoute(ARD_CATEGORY_SEARCH), element: <SearchResultsPage />, loader: SRPDataLoader },
        { path: getPageRoute(ARD_SUB_CATEGORY_SEARCH), element: <SearchResultsPage />, loader: SRPDataLoader },
        { path: getPageRoute(ARD_SUB_SUB_CATEGORY_SEARCH), element: <SearchResultsPage />, loader: SRPDataLoader },
        {
          path: '*',
          element: <NotFoundPage />,
          loader: NotFoundPageDataLoader,
        },
      ].filter(Boolean),
    },
  ].filter(Boolean);
};

export default getRoutes;
