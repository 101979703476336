import MobileDetect from 'mobile-detect';
import { getValidResponseBody, searchApi, staticApi } from '../../api';
import { DEFAULT_NUMBER_PRODUCTS_PER_PAGE, SORTING_OPTIONS_RELEVANCE } from '../../utils/getSearchPayload';
import { getSearchApiOptions } from './getSearchApiOptions';
import { getAuthHeader } from '../../utils/getAuthHeader';

const createFeaturesFilter = (search) => {
  const baseFilters = [
    'brands',
    'colors',
    'genders',
    'pageSize',
    'page',
    'priceFrom',
    'priceTo',
    'q',
    'sel',
    'shops',
    'sizes',
    'sortBy',
    'view',
    'webshop',
    'clk',
  ];
  const queryStringParams = new URLSearchParams(search.replace(/\|/g, '%7C'));

  const featuresFilter = Array.from(queryStringParams.entries())
    .filter(([key]) => !baseFilters.includes(key))
    .map(([key, value]) => ({ key, values: value.split('|').filter(Boolean) }));

  return featuresFilter;
};

const parseSearchParam = (url) => {
  const brandsParam = url.searchParams.get('brand') || '';
  const colorsParam = url.searchParams.get('color') || '';
  const gendersParam = url.searchParams.get('gender') || '';
  const deliveryParam = url.searchParams.get('delivery') || '';
  const modelsParam = url.searchParams.get('model') || '';
  const shopsParam = url.searchParams.get('webshop') || '';
  const sizesParam = url.searchParams.get('size') || '';

  const maxProductsPerPage = url.searchParams.get('pageSize') || DEFAULT_NUMBER_PRODUCTS_PER_PAGE;
  const page = url.searchParams.get('page') || 1;
  const urlSortingOption = url.searchParams.get('sortBy') || SORTING_OPTIONS_RELEVANCE;

  const priceFromURLParam = url.searchParams.get('priceFrom');
  const priceFrom =
    typeof priceFromURLParam === 'string' ? parseInt(url.searchParams.get('priceFrom'), 10) : priceFromURLParam;

  const priceToURLParam = url.searchParams.get('priceTo');
  const priceTo = typeof priceToURLParam === 'string' ? parseInt(priceToURLParam, 10) : priceToURLParam;

  const searchTerm = url.searchParams.get('q') || '';

  const filters = {
    brands: brandsParam ? brandsParam.split('|') : [],
    colors: colorsParam ? colorsParam.split('|') : [],
    genders: gendersParam ? gendersParam.split('|') : [],
    delivery: deliveryParam ? deliveryParam.split('|') : [],
    models: modelsParam ? modelsParam.split('|') : [],
    shops: shopsParam ? shopsParam.split('|') : [],
    sizes: sizesParam ? sizesParam.split('|') : [],
  };

  return { filters, page, priceFrom, priceTo, searchTerm, maxProductsPerPage, urlSortingOption };
};

export const SRPDataLoader = async ({ params, request }) => {
  try {
    const { category, subCategory, subSubCategory, uberfilter } = params;
    const categoriesParamArray = [category, subCategory, subSubCategory].filter(Boolean);
    const categoriesJSONPath = categoriesParamArray.join('.');
    const authHeader = getAuthHeader();
    const url = new URL(request.url);

    const { filters, page, priceFrom, priceTo, searchTerm, maxProductsPerPage, urlSortingOption } = parseSearchParam(
      url
    );
    //todo: might be better to run it once on server side and read value in loaders
    const md = new MobileDetect(request?.headers.get('user-agent'));
    const isDesktop = !md.mobile();

    const featuresFilter = createFeaturesFilter(url.search);
    const { options: searchApiOptions, searchPayload } = getSearchApiOptions(
      filters,
      page,
      priceFrom,
      priceTo,
      searchTerm,
      categoriesParamArray,
      maxProductsPerPage,
      urlSortingOption,
      featuresFilter,
      uberfilter,
      authHeader,
      isDesktop
    );

    const [searchDataResp, categoriesSEODataResp] = await Promise.all([
      searchApi.getSearchData(searchApiOptions),
      !!categoriesJSONPath ? staticApi.getCategories2Data(categoriesJSONPath) : null,
    ]);

    //to be fair, search api returns only code 200, even for no results. this allows to provide search suggestions
    //this handler is here just for failsafe reasons
    if (!searchDataResp?.ok) throw new Response('Not found', { status: 404 });

    const categoryData = getValidResponseBody(categoriesSEODataResp);
    const searchData = getValidResponseBody(searchDataResp);

    return {
      categoryData,
      searchData,
      searchApiOptions,
      authHeader,
      featuresFilter,
      filters,
      categoriesParamArray,
      maxProductsPerPage,
      priceFrom,
      priceTo,
      page,
      searchTerm,
      urlSortingOption,
      searchPayload,
    };
  } catch (error) {
    throw new Response(error.message, { status: 404 });
  }
};
