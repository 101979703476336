/** Actions' constants */
export const ACTION_SET_DEFERRED_PWA_INSTALL_PROMPT = 'ACTION_SET_DEFERRED_PWA_INSTALL_PROMPT';

/** Initial state */
export const INITIAL_STATE = {
  deferredPWAInstallPrompt: null,
};

export const reducer = (currentState = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTION_SET_DEFERRED_PWA_INSTALL_PROMPT:
      return {
        ...currentState,
        deferredPWAInstallPrompt: payload,
      };
    default:
      return currentState;
  }
};

/** Action (creators) */
export const setDeferredPWAInstallPrompt = (deferredPWAInstallPrompt) => ({
  payload: deferredPWAInstallPrompt,
  type: ACTION_SET_DEFERRED_PWA_INSTALL_PROMPT,
});
