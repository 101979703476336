import { discountApi, getValidResponseBody } from '../../api';

export const HomePageMPDataLoader = async () => {
  try {
    const discountsData = await discountApi.getHomePageDiscounts();
    const discounts = getValidResponseBody(discountsData);

    return { discounts };
  } catch (error) {
    throw new Response(error.message, { status: 404 });
  }
};
