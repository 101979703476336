import { useAuth0 } from '@auth0/auth0-react';
import { isMPGTMEnabled } from './isGTMEnabled';
import { useSelector } from 'react-redux';
import useAdultContentHandlers from './useAdultContentHandlers';
import React, { useCallback, useState, useContext } from 'react';
import { ARD_HOME, ARD_POPUP_LOGIN } from './appRoutes.definitions';
import { getPopupOnPagePath } from './appRoutes';
import useNotifications from '../components/NotificationPopup/useNotifications';
import { useUserProfileState } from './useUserProfileState';
import dataLayerPush from './dataLayerPush';

export const auth0RedirectURI = `${process.env.REACT_APP_BASE_URL}/auth-redirect`;
export const Auth0WrapperContext = React.createContext();
export const useAuth0Wrapper = () => {
  const context = useContext(Auth0WrapperContext);
  return context || {};
};

export const Auth0WrapperProvider = ({ children }) => {
  //https://auth0.github.io/auth0-react/functions/useAuth0.html
  const { loginWithRedirect, logout, isAuthenticated, isLoading, user, getIdTokenClaims } = useAuth0();
  const { unblockAdultContent, forbidAdultContent } = useAdultContentHandlers();
  const { sendNotification } = useNotifications();
  const profile = useSelector(({ profile }) => profile);
  const { setReduxProfile } = useUserProfileState();
  const [tokenClaims, setTokenClaims] = useState(null);
  //while signupRequired is set to true, signup process (agreement and signup request) is in progress
  const [signupRequired, setSignupRequired] = useState(false);
  const setSignupAttemptCompleted = () => setSignupRequired(false);

  const loginWithRedirectWrapped = async (...args) => {
    if (isMPGTMEnabled) dataLayerPush({ event: 'login_attempt' });
    await loginWithRedirect(...args);
    if (isMPGTMEnabled) {
      localStorage.setItem('login_attempt_pending', true);
    }
  };

  const logoutWithCleanSession = useCallback(
    (logoutFn) => {
      localStorage.removeItem('reduxProfile');
      localStorage.removeItem('tokenClaims');
      setReduxProfile(null);
      //content should be blocked only for underage users
      unblockAdultContent();
      //but still should be hidden by default
      forbidAdultContent();
      if (isMPGTMEnabled) dataLayerPush({ event: 'logout_attempt' });
      return logoutFn();
    },
    [forbidAdultContent, setReduxProfile, unblockAdultContent]
  );

  const logoutWithRedirect = () =>
    logoutWithCleanSession(logout({ logoutParams: { returnTo: `${process.env.REACT_APP_BASE_URL}/auth/logout` } }));

  const logoutExpiredSession = useCallback(
    (notificationText) => {
      const redirectToLogin = () => {
        window.open(getPopupOnPagePath(ARD_POPUP_LOGIN, ARD_HOME), '_self');
      };

      if (notificationText) {
        sendNotification(notificationText);
      }

      if (logout) logoutWithCleanSession(() => logout({ returnTo: `${process.env.REACT_APP_BASE_URL}/auth/logout` }));
      else logoutWithCleanSession(redirectToLogin);
    },
    [logout, logoutWithCleanSession, sendNotification]
  );
  //todo: currently only cartApi handles JWT on api side
  //so only cart api is able to provide 401 on expired JWT
  //on client side that's ONE way to handle expired JWT
  //but make sure to implement JWT expiration handling
  //upd: on auth0 loading we check if session is still authorized and invoke logoutExpiredSession if not
  const logoutExpiredJWT = (apiResponse) => {
    if (apiResponse.status === 401 && profile) logoutExpiredSession();
  };

  return (
    <Auth0WrapperContext.Provider
      value={{
        loginWithRedirect: loginWithRedirectWrapped,
        logout,
        logoutWithRedirect,
        isAuthenticated,
        auth0User: user,
        signupRequired,
        setSignupAttemptCompleted,
        tokenClaims,
        isLoading,
        getIdTokenClaims,
        logoutExpiredJWT,
        logoutExpiredSession,
        setSignupRequired,
        setTokenClaims,
      }}
    >
      {children}
    </Auth0WrapperContext.Provider>
  );
};
