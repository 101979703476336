import { contentApi, getValidResponseBody } from '../../api';

export const InfoPageDataLoader = async () => {
  try {
    const latestPagesResp = await contentApi.getLatestPages();
    const latestPages = getValidResponseBody(latestPagesResp);

    if (!latestPagesResp?.ok) throw new Response('Not Found', { status: 404 });

    return { latestPages };
  } catch (error) {
    throw new Response(error.message, { status: 404 });
  }
};
