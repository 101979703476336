import { redirect } from 'react-router-dom';
import { contentApi, getValidResponseBody } from '../../api';

export const SDPDataLoader = async ({ params }) => {
  try {
    const { shopWebsite } = params;
    const shopInfoResp = await contentApi.getShopInfo(shopWebsite);

    if (!shopInfoResp?.ok) throw new Response('Not Found', { status: shopInfoResp?.status });

    const requestedRedirect = !!shopInfoResp?.body?.location;
    if (requestedRedirect) return redirect(requestedRedirect);

    const shopInfo = getValidResponseBody(shopInfoResp);
    return { shopInfo };
  } catch (error) {
    throw new Response(error.message, { status: 404 });
  }
};
