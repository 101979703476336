export const DAILY_BAR = 'DailyBar';
export const BLOGS_LIST_PAGE = 'BlogsListPage';
export const BLOG_PAGE = 'BlogPage';
export const HEADER_DAILIES = 'HeaderDailies';
export const CART = 'Cart';
export const HAMBURGER = 'Hamburger';
export const POPULAR_SEARCH_TERMS = 'PopularSearchTerms';
export const LOGO_REEL = 'LogoReel';
export const HOME_MAIN_ARROW_DOWN = 'HomeMainArrowDown';
export const BLOG_GRID = 'BlogGrid';
export const BRANDS_SLIDER_SECTION = 'BrandsSliderSection';
export const FEATURED_CATEGORIES_SECTION = 'FeaturedCategoriesSection';
export const PRODUCT_CARD_TO_PDP = 'ProductCardToPDP';
export const PRODUCT_CARD_WITH_PDC = 'ProductCardWithPDC';
export const PRODUCT_CARD_TO_PDP_LINK = 'ProductCardToPDPLink';
export const BOOKMARKS = 'Bookmarks';
export const COMING_SOON = 'ComingSoon';
export const IMAGE_SEARCH_FEATURE = 'ImageSearchFeature';
export const IMAGE_SEARCH_CROP = 'ImageSearchCrop';
export const TOP_RELEVANT_CATEGORIES = 'TopRelevantCategories';
