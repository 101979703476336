import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { CookiesProvider } from 'react-cookie';
import { FrontloadProvider } from 'react-frontload';
import { ImageCropSearchProvider } from '../components/ImageCropSearch/ImageCropSearchContext';
import { SSRContext } from '../utils/SSRContext';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';
import { Auth0Provider } from '@auth0/auth0-react';
import { auth0RedirectURI, Auth0WrapperProvider } from '../utils/auth0';

const CrossEnvProviders = ({ children, store, frontloadState, ssrContextObject, cookies = undefined }) => {
  const intl = store.getState().intl;

  return (
    <ErrorBoundary>
      <ReduxProvider store={store}>
        <IntlProvider key={intl.locale} locale={intl.locale} messages={intl.messages}>
          <FrontloadProvider initialState={frontloadState}>
            <CookiesProvider cookies={cookies}>
              <Auth0Provider
                clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
                domain={process.env.REACT_APP_AUTH0_DOMAIN}
                authorizationParams={{
                  redirect_uri: auth0RedirectURI,
                }}
              >
                <Auth0WrapperProvider>
                  <ImageCropSearchProvider>
                    <SSRContext.Provider value={ssrContextObject}>{children}</SSRContext.Provider>
                  </ImageCropSearchProvider>
                </Auth0WrapperProvider>
              </Auth0Provider>
            </CookiesProvider>
          </FrontloadProvider>
        </IntlProvider>
      </ReduxProvider>
    </ErrorBoundary>
  );
};

export default CrossEnvProviders;
