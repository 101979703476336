/* eslint-disable react/prop-types */
import './index.scss';
//todo: set names for those chunks and manually set media attribute for meta links
// import '../styleguide/layout-s.scss';
// import '../styleguide/layout-m.scss';
// import '../styleguide/layout-l.scss';
// import '../styleguide/layout-xl.scss';
import '../styleguide/layout-responsive.scss';
import styles from './App.module.scss';

import loadable from '@loadable/component';
import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { CONFIG_SHAPE } from '../state/config/config';

import { useAuth0Wrapper } from '../utils/auth0';
import { Outlet, useLocation } from 'react-router-dom';
import { CART } from '../features/SwitchableFeature/SwitchableFeature.definitions';
import { useSearchHistoryInit } from '../utils/useSearchHistoryInit';
import { isFeatureEnabled } from '../features/SwitchableFeature/SwitchableFeature';
import { isCookieConsentRequired } from '../utils/isCookieConsentRequired';
import useWishlistUpdater from '../utils/useWishlistUpdater';
import useAdultContentInit from '../utils/useAdultContentInit';
import { useAuth0Effects } from '../utils/useAuth0Effects';
import loadVisibleComponent from '../utils/loadVisibleComponent';
import useSwitchableFeatureBootstrap from './useSwitchableFeatureBootstrap';
import useClickoutData from './useClickoutData';
import CommonHead from './CommonHead';
import OffScreen from './OffScreen';
import useClientScriptInjector from './useClientScriptInjector';
import { usePWAInstallPromptInit } from '../utils/usePWAInstallPromptInit';

const Gdpr = loadable(() => import('../components/Gdpr/Gdpr'));
const FloatingButton = loadVisibleComponent(() => import('../components/FloatingButton/FloatingButton'));
const ProfilePrivacyAgreementPopup = loadVisibleComponent(() => import('../components/ProfilePrivacyAgreementPopup'));

const scrollToTop = () => {
  window.scrollTo({ behavior: 'smooth', left: 0, top: 0 });
};

const AppLayout = ({ config }) => {
  const { search, pathname } = useLocation();
  const { signupRequired } = useAuth0Wrapper();

  const [cookies] = useCookies(['enabledFeatureFlags']);
  useSwitchableFeatureBootstrap(search);
  useSearchHistoryInit();
  useWishlistUpdater();
  useAdultContentInit();
  useAuth0Effects();
  useClickoutData();
  useClientScriptInjector();
  usePWAInstallPromptInit();

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  const classList = classNames(styles.appRoot, {
    [styles.appRootMarketplace]: isFeatureEnabled(cookies, config, CART),
  });

  return (
    <>
      <CommonHead />
      <div className={classList}>
        <Outlet />
        <OffScreen />
        <div className={classNames(styles.float, 'container')}>
          <FloatingButton gap={15} border={true} onClick={scrollToTop}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <path
                className={styles.floatingButton}
                fillRule="nonzero"
                d="M4.923 10.91L4 10l.923-.91L14.153 0 16 1.818 7.664 9.972 16 18.182 14.154 20z"
              />
            </svg>
          </FloatingButton>
        </div>
        {signupRequired && <ProfilePrivacyAgreementPopup />}
        {!!isCookieConsentRequired && <Gdpr />}
      </div>
    </>
  );
};

AppLayout.displayName = 'AppLayout';
AppLayout.propTypes = {
  config: CONFIG_SHAPE,
};

const mapStateToProps = ({ config }) => ({
  config,
});
export default connect(mapStateToProps)(AppLayout);
