import { useAuth0Wrapper } from './auth0';
import { parseValidJSON } from './parseValidJSON';
import { useProfileInit } from './useProfileInit';
import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isMPGTMEnabled } from './isGTMEnabled';
import { useSelector } from 'react-redux';
import { useUserProfileState } from './useUserProfileState';
import useNotifications from '../components/NotificationPopup/useNotifications';
import dataLayerPush from './dataLayerPush';
import { useCallback } from 'react';

export const useAuth0Effects = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const {
    isAuthenticated,
    auth0User,
    signupRequired,
    tokenClaims,
    setTokenClaims,
    getIdTokenClaims,
    logoutExpiredSession,
    setSignupRequired,
  } = useAuth0Wrapper();
  const profile = useSelector(({ profile }) => profile);
  const profileString = JSON.stringify(profile);
  const { setReduxProfile } = useUserProfileState();
  const { sendNotification } = useNotifications();

  const { processProfile } = useProfileInit(
    tokenClaims,
    signupRequired,
    setSignupRequired,
    auth0User,
    setReduxProfile,
    logoutExpiredSession
  );

  const isReduxProfileSyncedWithApiRef = useRef(false);
  const setReduxProfileSyncedWithApi = () => {
    isReduxProfileSyncedWithApiRef.current = true;
  };

  const redirectToTargetURL = useCallback(() => {
    const loginInitURL = localStorage.getItem('loginInitURL');
    localStorage.removeItem('loginInitURL');
    navigate(loginInitURL || '/');
  }, [navigate]);

  useEffect(() => {
    window.testEL = logoutExpiredSession;
  }, [logoutExpiredSession]);

  useEffect(() => {
    if (isAuthenticated) {
      getIdTokenClaims().then((claims) => {
        setTokenClaims((prevClaims) => {
          const pendingLoginAttempt = localStorage.getItem('login_attempt_pending');
          if (isMPGTMEnabled && !prevClaims && pendingLoginAttempt) {
            //register login via GTM
            dataLayerPush({
              event: 'login_success',
              user_id: claims?.sub, //sub (subject): Subject of the JWT (the user)
            });
            localStorage.removeItem('login_attempt_pending');
          }
          return claims;
        });
        localStorage.setItem('tokenClaims', JSON.stringify(claims));
      });
    }
  }, [getIdTokenClaims, isAuthenticated, setTokenClaims]);

  useEffect(() => {
    (async () => {
      const redirectPage = pathname.includes('/auth-redirect');
      if (redirectPage && search.includes('error=')) {
        redirectToTargetURL();
        sendNotification('Login failed. Try again');
        return;
      }
      if (!redirectPage || !search.includes('code=')) return;
      //wait until auth0 library loads data until further redirect
      if (auth0User && tokenClaims) redirectToTargetURL();
    })();
  }, [auth0User, pathname, redirectToTargetURL, search, sendNotification, tokenClaims]);

  useEffect(() => {
    if (!profile && isAuthenticated) {
      //Load previously authorized user profile to provide user data at page load

      //const localStorageTokenClaimsString = localStorage.getItem('tokenClaims');
      const localStorageReduxProfileString = localStorage.getItem('reduxProfile');

      //const localStorageTokenClaims = localStorageTokenClaimsString && parseValidJSON(localStorageTokenClaimsString);
      const localStorageReduxProfileUser =
        localStorageReduxProfileString && parseValidJSON(localStorageReduxProfileString);

      if (localStorageReduxProfileUser) {
        setReduxProfile({ ...localStorageReduxProfileUser });
        //todo: validate this JWT
        //setTokenClaims(localStorageTokenClaims);
      }
    }
    //Request up to date profile data from api
    if (tokenClaims?.__raw && !isReduxProfileSyncedWithApiRef.current) {
      processProfile(setReduxProfileSyncedWithApi);
    }
    //todo: processProfile and profile dependencies make infinite loop
  }, [tokenClaims?.__raw, profileString, setReduxProfile, isAuthenticated]);
};
